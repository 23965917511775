<template>
	<div>
		<!-- IF FILTER BY IS ARCHITECT -->
		<v-select
			v-if="filterBy === 'architect'"
			v-model="architectSelected"
			:options="lists.architects"
			:get-option-label="(option) => option.name"
			:reduce="(option) => option.id"
			:clearable="false"
			@input="emitEvent"
		/>
		<!-- IF FILTER BY IS SYNDIC -->
		<v-select
			v-if="filterBy === 'syndic'"
			v-model="syndicSelected"
			:options="lists.syndics"
			:get-option-label="(option) => option.name"
			:reduce="(option) => option.id"
			:clearable="false"
			@input="emitEvent"
		/>
		<!-- IF FILTER BY IS PRIORITY -->
		<v-select
			v-if="filterBy === 'priority'"
			v-model="prioritySelected"
			:options="lists.priorities"
			:get-option-label="(option) => option.text"
			:reduce="(option) => option.value"
			:clearable="false"
			@input="emitEvent"
		/>
		<!-- IF FILTER BY IS STATUS -->
		<v-select
			v-if="filterBy === 'status'"
			v-model="statusSelected"
			:options="lists.statuses"
			:get-option-label="(option) => option.text"
			:reduce="(option) => option.value"
			:clearable="false"
			@input="emitEvent"
		>
			<template #option="{ text, value }">
				<span style="display: inline-block; border-radius: 2px; width: 12px; height: 12px; margin-right: 10px" :class="`bg-status${value}`" />
				{{ text }}
			</template>
		</v-select>

		<!-- IF FILTER BY IS TEAM -->
		<v-select
			v-if="filterBy === 'team'"
			v-model="teamSelected"
			:options="lists.teams"
			:get-option-label="(option) => option.name"
			:reduce="(option) => parseInt(option.id)"
			:clearable="false"
			@input="emitEvent"
		>
			<template #option="{ name, color }">
				<span
					style="display: inline-block; border-radius: 2px; width: 12px; height: 12px; margin-right: 10px"
					:style="`background-color: #${base16ToHexa(color)}`"
				/>
				{{ name }}
			</template>
		</v-select>

		<!-- IF FILTER BY IS CLIENT -->
		<v-select
			v-if="filterBy === 'client'"
			v-model="clientSelected"
			:options="lists.clients"
			:get-option-label="(option) => option.name"
			:reduce="(option) => parseInt(option.id)"
			:clearable="false"
			@input="emitEvent"
		/>

		<!-- IF FILTER BY IS TASKTYPE -->
		<v-select
			v-if="filterBy === 'tasktype'"
			v-model="taskTypeSelected"
			:options="lists.taskTypes"
			:get-option-label="(option) => option.name"
			:reduce="(option) => parseInt(option.id)"
			:clearable="false"
			@input="emitEvent"
		>
			<template #option="{ name }">
				{{ name }}
			</template>
		</v-select>

		<!-- IF FILTER BY IS USER -->
		<v-select
			v-if="filterBy === 'user'"
			v-model="userSelected"
			:options="lists.users"
			:get-option-label="(option) => `${option.name} ${option.firstName}`"
			:reduce="(option) => parseInt(option.id)"
			:clearable="false"
			@input="emitEvent"
		>
			<template #selected-option="{ name, firstName, picture }">
				<div v-if="picture === ''" style="background-color: gray" class="not-img">
					<span>{{ getInitial(`${name} ${firstName}`) }}</span>
				</div>
				<img v-if="picture !== ''" :src="pathPicture(picture)" :alt="picture" class="img-circle-custom" />
				{{ splitWord(name + ' ' + firstName) }}
			</template>
			<template #option="{ name, firstName, picture }">
				<div v-if="picture === ''" style="background-color: gray" class="not-img">
					<span>{{ getInitial(`${name} ${firstName}`) }}</span>
				</div>
				<img v-if="picture !== ''" :src="pathPicture(picture)" :alt="picture" class="img-circle-custom" />
				{{ name + ' ' + firstName }}
			</template>
		</v-select>
		<!--v-select
      class="mb-2"
      :options="taskTypes"
    >
      <template #selected-option="option">
            <span v-if="option.picture">
              <b-img
                :src="showPicture(option.picture)"
                :alt="option.picture"
                width="26"
                height="26"
                class="m1"
              />
              <span style="margin-left: 10px">{{ option.name }}</span>
            </span>
        <span v-else>
              <b-img
                v-bind="mainPropsThumb"
                rounded="circle"
                alt="Circle image"
              />
              <span style="margin-left: 10px">{{ option.name }}</span>
            </span>
      </template>
      <template #option="option">
            <span v-if="option.picture">
              <b-img
                :src="showPicture(option.picture)"
                rounded="circle"
                :alt="option.picture"
                width="30"
                height="30"
                class="m1"
              />
              <span style="margin-left: 10px">{{ option.name }}</span>
            </span>
        <span v-else>
              <b-img
                v-bind="mainProps"
                rounded="circle"
                alt="Circle image"
              />
              <span style="margin-left: 10px">{{ option.name }}</span>
            </span>
      </template>
      <template #no-options="{ search, searching }">
        <template v-if="searching">
          {{ FormMSG(1, 'No results for') }} <em>{{ search }}</em>. <span>{{ FormMSG(2, 'You can add it') }}.</span>
        </template>
        <em v-else style="opacity: 0.5">
          {{ FormMSG(3, 'Start typing to search for a task type') }}.
        </em>
      </template>
    </v-select-->
	</div>
</template>

<script>
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ListFilterBy',

	mixins: [globalMixin],

	props: {
		filterBy: { type: String, default: 'user', required: true },
		indexParent: { type: Number, required: true },
		lists: { type: Object, required: true },
		valueSelected: { type: [Object, Number, String], default: null }
	},

	data() {
		return {
			userSelected: null,
			teamSelected: null,
			clientSelected: null,
			locationSelected: null,
			statusSelected: null,
			taskTypeSelected: null,
			prioritySelected: null,
			syndicSelected: null,
			architectSelected: null
		};
	},

	mounted() {
		this.checkValueSelected();
	},

	methods: {
		checkValueSelected() {
			if (this.filterBy === 'priority') {
				this.prioritySelected = this.valueSelected;
			} else if (this.filterBy === 'status') {
				this.statusSelected = this.valueSelected;
			} else if (this.filterBy === 'team') {
				this.teamSelected = this.valueSelected;
			} else if (this.filterBy === 'client') {
				this.clientSelected = this.valueSelected;
			} else if (this.filterBy === 'tasktype') {
				this.taskTypeSelected = this.valueSelected;
			} else if (this.filterBy === 'user') {
				this.userSelected = this.valueSelected;
			}
		},
		/**
		 * @param word{String}
		 * @returns {String}
		 */
		splitWord(word) {
			return word.substring(0, 8);
		},
		emitEvent(payload) {
			this.$emit('filter:selected', {
				index: this.indexParent,
				payload
			});
		}
	}
};
</script>

<style scoped></style>
