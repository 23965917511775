var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.setIsOpen,
          expression: "setIsOpen",
        },
      ],
      staticClass: "mt-2",
    },
    [
      _vm.isOffer
        ? _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: _vm.filters.length > 0 ? 4 : 6 } },
                    [
                      _c("dropdown-user-team", {
                        class: `${
                          _vm.$screen.width < 768 && _vm.filters.length > 0
                            ? "mb-2"
                            : ""
                        }`,
                        staticStyle: { width: "100%" },
                        attrs: {
                          "is-user": false,
                          "is-client": true,
                          clients: _vm.lists.clients,
                          initialize: _vm.initialize,
                          "value-selected": _vm.clientSelected,
                        },
                        on: {
                          "client:selected": _vm.handleClientSelected,
                          "client:removed": _vm.handleClientRemoved,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: _vm.filters.length > 0 ? 4 : 6 } },
                    [
                      _c(
                        "b-button",
                        {
                          class: `${
                            _vm.$screen.width < 768 && _vm.filters.length > 0
                              ? "mb-2"
                              : ""
                          }`,
                          attrs: {
                            variant:
                              _vm.haveFilter || _vm.isOpen
                                ? "warning"
                                : "light",
                            block: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isOpen = !_vm.isOpen
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(95, "Advanced filter")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: _vm.filters.length > 0 ? 4 : 0 } },
                    [
                      _vm.filters.length > 0
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "danger", block: "" },
                              on: { click: _vm.clickBtnClearFilter },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-ban",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(156, "Clear filter")) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.isMyPlannings
        ? _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: `${_vm.filters.length > 0 ? 3 : 4}` } },
                    [
                      _c("dropdown-user-team", {
                        class: `${_vm.$screen.width < 768 ? "mb-2" : ""}`,
                        staticStyle: { width: "100%" },
                        attrs: {
                          "is-user": true,
                          users: _vm.lists.users,
                          initialize: _vm.initialize,
                          "value-selected": _vm.userSelected,
                        },
                        on: {
                          "user:selected": _vm.handleUserSelected,
                          "user:removed": _vm.handleUserRemoved,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: `${_vm.filters.length > 0 ? 3 : 4}` } },
                    [
                      _c("dropdown-user-team", {
                        class: `${_vm.$screen.width < 768 ? "mb-2" : ""}`,
                        staticStyle: { width: "100%" },
                        attrs: {
                          "is-user": false,
                          teams: _vm.lists.teams,
                          initialize: _vm.initialize,
                          "value-selected": _vm.teamSelected,
                        },
                        on: {
                          "team:selected": _vm.handleTeamSelected,
                          "team:removed": _vm.handleTeamRemoved,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: `${_vm.filters.length > 0 ? 3 : 4}` } },
                    [
                      _c(
                        "b-button",
                        {
                          class: `${
                            _vm.$screen.width < 768 && _vm.filters.length > 0
                              ? "mb-2"
                              : ""
                          }`,
                          attrs: {
                            variant:
                              _vm.haveFilter || _vm.isOpen
                                ? "warning"
                                : "light",
                            block: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isOpen = !_vm.isOpen
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-filter",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(95, "Advanced filter")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.filters.length > 0
                    ? _c(
                        "b-col",
                        { attrs: { sm: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger", block: "" },
                              on: { click: _vm.clickBtnClearFilter },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-ban",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(156, "Clear filter")) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
          ],
          staticStyle: {
            position: "absolute",
            top: "35px",
            "z-index": "4000",
            transition: "width 0.4s",
            right: "0",
          },
          style: _vm.haveFilter ? "width: 500px" : "width: 200px",
          attrs: { id: "container-filter" },
        },
        [
          _c(
            "b-card",
            { attrs: { title: _vm.FormMSG(58, "Actif filters") } },
            [
              _vm._l(_vm.filters, function (filter, index) {
                return _c(
                  "b-card-text",
                  { key: index },
                  [
                    !filter.singleFilterUser &&
                    !filter.singleFilterTeam &&
                    !filter.singleFilterClient
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "2" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: _vm.firstOptions,
                                    clearable: false,
                                  },
                                  on: { "option:selected": _vm.emitEvent },
                                  model: {
                                    value: filter.firstOption,
                                    callback: function ($$v) {
                                      _vm.$set(filter, "firstOption", $$v)
                                    },
                                    expression: "filter.firstOption",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "3" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: _vm.listFilters,
                                    clearable: false,
                                  },
                                  on: { "option:selected": _vm.emitEvent },
                                  model: {
                                    value: filter.filterBy,
                                    callback: function ($$v) {
                                      _vm.$set(filter, "filterBy", $$v)
                                    },
                                    expression: "filter.filterBy",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: {
                                  cols:
                                    filter.secondOption.value === "undefined" ||
                                    filter.secondOption.value === "defined"
                                      ? 5
                                      : 2,
                                },
                              },
                              [
                                _c("v-select", {
                                  attrs: {
                                    options: _vm.secondOptions,
                                    clearable: false,
                                  },
                                  on: { "option:selected": _vm.emitEvent },
                                  model: {
                                    value: filter.secondOption,
                                    callback: function ($$v) {
                                      _vm.$set(filter, "secondOption", $$v)
                                    },
                                    expression: "filter.secondOption",
                                  },
                                }),
                              ],
                              1
                            ),
                            filter.secondOption.value !== "undefined" &&
                            filter.secondOption.value !== "defined"
                              ? _c(
                                  "b-col",
                                  { staticClass: "pr-0", attrs: { cols: "3" } },
                                  [
                                    _c("list-filter-by", {
                                      attrs: {
                                        "filter-by": filter.filterBy.value,
                                        "index-parent": index,
                                        lists: _vm.lists,
                                        "value-selected": filter.filterSelected,
                                      },
                                      on: {
                                        "filter:selected":
                                          _vm.handleFilterSelected,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-col",
                              {
                                staticClass: "pt-1 pr-0",
                                attrs: { cols: "2" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btn btn-outline-danger",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteAFilter(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-times",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "div",
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "card-link p-0",
                      attrs: {
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "fa fa-plus",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(2, "Add a filter")) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    _vm._l(_vm.listFilters, function (listFilter, index) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addNewFilter(listFilter)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(listFilter.label) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }