<template>
	<div>
		<div v-if="isClient">
			<b-dropdown :variant="clientSelected ? 'warning' : 'light'" style="width: 100%" menu-class="w-100">
				<template #button-content>
					<span v-if="clientSelected === null">
						{{ FormMSG(34, 'Client') }}
					</span>
					<span v-else>
						<span style="margin-left: 5px">{{ clientSelected.name }}</span>
					</span>
				</template>
				<b-dropdown-item
					v-for="(client, index) in clients"
					:key="index"
					href="#"
					@click="setClientSelected(client)"
					:active="clientSelected === client"
				>
					<span style="margin-left: 5px">{{ client.name }}</span>
				</b-dropdown-item>
			</b-dropdown>
		</div>
		<div v-else>
			<b-dropdown v-if="isUser" :variant="userSelected ? 'warning' : 'light'" style="width: 100%" menu-class="w-100">
				<template #button-content>
					<span v-if="userSelected === null">
						{{ FormMSG(1, 'User') }}
					</span>
					<span v-else v-lazy-container="{ selector: 'img' }">
						<img
							:data-src="pathPicture(userSelected.picture)"
							data-error="img/brand/avatar.jpg"
							data-loading="img/brand/tenor.gif"
							class="img-circle-custom"
						/>
						{{ userSelected.fullName }}
					</span>
				</template>
				<b-dropdown-item
					v-for="(user, index) in users"
					:key="index"
					v-lazy-container="{ selector: 'img' }"
					href="#"
					@click="setUserSelected(user)"
					:active="userSelected === user"
				>
					<img :data-src="pathPicture(user.picture)" data-error="img/brand/avatar.jpg" data-loading="img/brand/tenor.gif" class="img-circle-custom" />
					{{ user.fullName }}
				</b-dropdown-item>
			</b-dropdown>
			<b-dropdown v-else :variant="teamSelected ? 'warning' : 'light'" style="width: 100%" menu-class="w-100">
				<template #button-content>
					<span v-if="teamSelected === null">
						{{ FormMSG(2, 'Team') }}
					</span>
					<span v-else>
						<b-img
							:blank-color="`${teamSelected.color > 0 ? '#' + base16ToHexa(teamSelected.color) : '#dfdede'}`"
							width="20"
							height="20"
							rounded="circle"
							blank
						/>
						<span style="margin-left: 5px">{{ teamSelected.name }}</span>
					</span>
				</template>
				<b-dropdown-item v-for="(team, index) in teams" :key="index" href="#" @click="setTeamSelected(team)" :active="teamSelected === team">
					<b-img
						:blank-color="`${team.color > 0 ? '#' + base16ToHexa(team.color) : '#dfdede'}`"
						width="20"
						height="20"
						rounded="circle"
						blank
						@click="setTeamSelected(team)"
					/>
					<span style="margin-left: 5px">{{ team.name }}</span>
				</b-dropdown-item>
			</b-dropdown>
		</div>
	</div>
</template>

<script>
import GlobalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'DropdownUserTeam',

	mixins: [GlobalMixin, languageMessages],

	props: {
		isUser: { type: Boolean, default: true },
		isClient: { type: Boolean, default: false },
		users: { type: Array, default: () => [] },
		teams: { type: Array, default: () => [] },
		clients: { type: Array, default: () => [] },
		initialize: { type: Boolean, default: false, required: true },
		valueSelected: { type: [Number, Object, String], default: null }
	},

	data() {
		return {
			userSelected: null,
			teamSelected: null,
			clientSelected: null
		};
	},

	watch: {
		initialize(val) {
			if (val) {
				this.userSelected = null;
				this.teamSelected = null;
				this.clientSelected = null;
				this.emitEventUser();
				this.emitEventTeam();
			}
		},
		valueSelected: {
			handler(val) {
				this.checkValueSelected(val);
			},
			immediate: true,
			deep: true
		}
	},

	mounted() {
		// this.checkValueSelected()
	},

	methods: {
		checkValueSelected(valueSelected) {
			if (this.isUser && !this.isClient) {
				this.userSelected = valueSelected;
			} else if (!this.isUser && !this.isClient) {
				this.teamSelected = valueSelected;
			} else if (!this.isUser && this.isClient) {
				this.clientSelected = valueSelected;
			}
		},
		setUserSelected(user) {
			if (user === this.userSelected) {
				this.userSelected = null;
				this.emitEventUser();
			} else {
				this.userSelected = user;
				this.emitEventUser();
			}
		},
		setTeamSelected(team) {
			if (team === this.teamSelected) {
				this.teamSelected = null;
				this.emitEventTeam();
			} else {
				this.teamSelected = team;
				this.emitEventTeam();
			}
		},
		setClientSelected(client) {
			if (client === this.clientSelected) {
				this.clientSelected = null;
				this.emitEventClient();
			} else {
				this.clientSelected = client;
				this.emitEventClient();
			}
		},
		emitEventClient() {
			if (this.clientSelected === null) {
				this.$emit('client:removed');
			} else {
				this.$emit('client:selected', this.clientSelected);
			}
		},
		emitEventUser() {
			if (this.userSelected === null) {
				this.$emit('user:removed');
			} else {
				this.$emit('user:selected', this.userSelected);
			}
		},
		emitEventTeam() {
			if (this.teamSelected === null) {
				this.$emit('team:removed');
			} else {
				this.$emit('team:selected', this.teamSelected);
			}
		}
	}
};
</script>

<style>
.img-circle-custom {
	border-radius: 50%;
	width: 25px;
	height: 25px;
}
</style>
