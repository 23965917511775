var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.filterBy === "architect"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.architects,
              "get-option-label": (option) => option.name,
              reduce: (option) => option.id,
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            model: {
              value: _vm.architectSelected,
              callback: function ($$v) {
                _vm.architectSelected = $$v
              },
              expression: "architectSelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "syndic"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.syndics,
              "get-option-label": (option) => option.name,
              reduce: (option) => option.id,
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            model: {
              value: _vm.syndicSelected,
              callback: function ($$v) {
                _vm.syndicSelected = $$v
              },
              expression: "syndicSelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "priority"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.priorities,
              "get-option-label": (option) => option.text,
              reduce: (option) => option.value,
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            model: {
              value: _vm.prioritySelected,
              callback: function ($$v) {
                _vm.prioritySelected = $$v
              },
              expression: "prioritySelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "status"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.statuses,
              "get-option-label": (option) => option.text,
              reduce: (option) => option.value,
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function ({ text, value }) {
                    return [
                      _c("span", {
                        class: `bg-status${value}`,
                        staticStyle: {
                          display: "inline-block",
                          "border-radius": "2px",
                          width: "12px",
                          height: "12px",
                          "margin-right": "10px",
                        },
                      }),
                      _vm._v("\n\t\t\t\t" + _vm._s(text) + "\n\t\t\t"),
                    ]
                  },
                },
              ],
              null,
              false,
              2408974098
            ),
            model: {
              value: _vm.statusSelected,
              callback: function ($$v) {
                _vm.statusSelected = $$v
              },
              expression: "statusSelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "team"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.teams,
              "get-option-label": (option) => option.name,
              reduce: (option) => parseInt(option.id),
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function ({ name, color }) {
                    return [
                      _c("span", {
                        staticStyle: {
                          display: "inline-block",
                          "border-radius": "2px",
                          width: "12px",
                          height: "12px",
                          "margin-right": "10px",
                        },
                        style: `background-color: #${_vm.base16ToHexa(color)}`,
                      }),
                      _vm._v("\n\t\t\t\t" + _vm._s(name) + "\n\t\t\t"),
                    ]
                  },
                },
              ],
              null,
              false,
              1317180045
            ),
            model: {
              value: _vm.teamSelected,
              callback: function ($$v) {
                _vm.teamSelected = $$v
              },
              expression: "teamSelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "client"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.clients,
              "get-option-label": (option) => option.name,
              reduce: (option) => parseInt(option.id),
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            model: {
              value: _vm.clientSelected,
              callback: function ($$v) {
                _vm.clientSelected = $$v
              },
              expression: "clientSelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "tasktype"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.taskTypes,
              "get-option-label": (option) => option.name,
              reduce: (option) => parseInt(option.id),
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function ({ name }) {
                    return [_vm._v("\n\t\t\t\t" + _vm._s(name) + "\n\t\t\t")]
                  },
                },
              ],
              null,
              false,
              99627857
            ),
            model: {
              value: _vm.taskTypeSelected,
              callback: function ($$v) {
                _vm.taskTypeSelected = $$v
              },
              expression: "taskTypeSelected",
            },
          })
        : _vm._e(),
      _vm.filterBy === "user"
        ? _c("v-select", {
            attrs: {
              options: _vm.lists.users,
              "get-option-label": (option) =>
                `${option.name} ${option.firstName}`,
              reduce: (option) => parseInt(option.id),
              clearable: false,
            },
            on: { input: _vm.emitEvent },
            scopedSlots: _vm._u(
              [
                {
                  key: "selected-option",
                  fn: function ({ name, firstName, picture }) {
                    return [
                      picture === ""
                        ? _c(
                            "div",
                            {
                              staticClass: "not-img",
                              staticStyle: { "background-color": "gray" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getInitial(`${name} ${firstName}`))
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      picture !== ""
                        ? _c("img", {
                            staticClass: "img-circle-custom",
                            attrs: {
                              src: _vm.pathPicture(picture),
                              alt: picture,
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.splitWord(name + " " + firstName)) +
                          "\n\t\t\t"
                      ),
                    ]
                  },
                },
                {
                  key: "option",
                  fn: function ({ name, firstName, picture }) {
                    return [
                      picture === ""
                        ? _c(
                            "div",
                            {
                              staticClass: "not-img",
                              staticStyle: { "background-color": "gray" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getInitial(`${name} ${firstName}`))
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      picture !== ""
                        ? _c("img", {
                            staticClass: "img-circle-custom",
                            attrs: {
                              src: _vm.pathPicture(picture),
                              alt: picture,
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(name + " " + firstName) +
                          "\n\t\t\t"
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              4126428240
            ),
            model: {
              value: _vm.userSelected,
              callback: function ($$v) {
                _vm.userSelected = $$v
              },
              expression: "userSelected",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }