var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isClient
      ? _c(
          "div",
          [
            _c(
              "b-dropdown",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  variant: _vm.clientSelected ? "warning" : "light",
                  "menu-class": "w-100",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _vm.clientSelected === null
                            ? _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(34, "Client")) +
                                    "\n\t\t\t\t"
                                ),
                              ])
                            : _c("span", [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v(_vm._s(_vm.clientSelected.name))]
                                ),
                              ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2627519381
                ),
              },
              _vm._l(_vm.clients, function (client, index) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: index,
                    attrs: { href: "#", active: _vm.clientSelected === client },
                    on: {
                      click: function ($event) {
                        return _vm.setClientSelected(client)
                      },
                    },
                  },
                  [
                    _c("span", { staticStyle: { "margin-left": "5px" } }, [
                      _vm._v(_vm._s(client.name)),
                    ]),
                  ]
                )
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.isUser
              ? _c(
                  "b-dropdown",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      variant: _vm.userSelected ? "warning" : "light",
                      "menu-class": "w-100",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _vm.userSelected === null
                                ? _c("span", [
                                    _vm._v(
                                      "\n\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(1, "User")) +
                                        "\n\t\t\t\t"
                                    ),
                                  ])
                                : _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "lazy-container",
                                          rawName: "v-lazy-container",
                                          value: { selector: "img" },
                                          expression: "{ selector: 'img' }",
                                        },
                                      ],
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "img-circle-custom",
                                        attrs: {
                                          "data-src": _vm.pathPicture(
                                            _vm.userSelected.picture
                                          ),
                                          "data-error": "img/brand/avatar.jpg",
                                          "data-loading": "img/brand/tenor.gif",
                                        },
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t" +
                                          _vm._s(_vm.userSelected.fullName) +
                                          "\n\t\t\t\t"
                                      ),
                                    ]
                                  ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1638530563
                    ),
                  },
                  _vm._l(_vm.users, function (user, index) {
                    return _c(
                      "b-dropdown-item",
                      {
                        directives: [
                          {
                            name: "lazy-container",
                            rawName: "v-lazy-container",
                            value: { selector: "img" },
                            expression: "{ selector: 'img' }",
                          },
                        ],
                        key: index,
                        attrs: { href: "#", active: _vm.userSelected === user },
                        on: {
                          click: function ($event) {
                            return _vm.setUserSelected(user)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-circle-custom",
                          attrs: {
                            "data-src": _vm.pathPicture(user.picture),
                            "data-error": "img/brand/avatar.jpg",
                            "data-loading": "img/brand/tenor.gif",
                          },
                        }),
                        _vm._v(
                          "\n\t\t\t\t" + _vm._s(user.fullName) + "\n\t\t\t"
                        ),
                      ]
                    )
                  }),
                  1
                )
              : _c(
                  "b-dropdown",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      variant: _vm.teamSelected ? "warning" : "light",
                      "menu-class": "w-100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _vm.teamSelected === null
                              ? _c("span", [
                                  _vm._v(
                                    "\n\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(2, "Team")) +
                                      "\n\t\t\t\t"
                                  ),
                                ])
                              : _c(
                                  "span",
                                  [
                                    _c("b-img", {
                                      attrs: {
                                        "blank-color": `${
                                          _vm.teamSelected.color > 0
                                            ? "#" +
                                              _vm.base16ToHexa(
                                                _vm.teamSelected.color
                                              )
                                            : "#dfdede"
                                        }`,
                                        width: "20",
                                        height: "20",
                                        rounded: "circle",
                                        blank: "",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v(_vm._s(_vm.teamSelected.name))]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  _vm._l(_vm.teams, function (team, index) {
                    return _c(
                      "b-dropdown-item",
                      {
                        key: index,
                        attrs: { href: "#", active: _vm.teamSelected === team },
                        on: {
                          click: function ($event) {
                            return _vm.setTeamSelected(team)
                          },
                        },
                      },
                      [
                        _c("b-img", {
                          attrs: {
                            "blank-color": `${
                              team.color > 0
                                ? "#" + _vm.base16ToHexa(team.color)
                                : "#dfdede"
                            }`,
                            width: "20",
                            height: "20",
                            rounded: "circle",
                            blank: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setTeamSelected(team)
                            },
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "5px" } }, [
                          _vm._v(_vm._s(team.name)),
                        ]),
                      ],
                      1
                    )
                  }),
                  1
                ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }