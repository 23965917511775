<template>
	<div v-click-outside="setIsOpen" class="mt-2">
		<div v-if="isOffer">
			<b-row>
				<b-col :sm="filters.length > 0 ? 4 : 6">
					<dropdown-user-team
						:is-user="false"
						:is-client="true"
						:clients="lists.clients"
						:initialize="initialize"
						:value-selected="clientSelected"
						@client:selected="handleClientSelected"
						@client:removed="handleClientRemoved"
						style="width: 100%"
						:class="`${$screen.width < 768 && filters.length > 0 ? 'mb-2' : ''}`"
					/>
				</b-col>
				<b-col :sm="filters.length > 0 ? 4 : 6">
					<b-button
						:variant="haveFilter || isOpen ? 'warning' : 'light'"
						@click="isOpen = !isOpen"
						block
						:class="`${$screen.width < 768 && filters.length > 0 ? 'mb-2' : ''}`"
					>
						<i class="fa fa-filter" aria-hidden="true" />
						{{ FormMSG(95, 'Advanced filter') }}
					</b-button>
				</b-col>
				<b-col :sm="filters.length > 0 ? 4 : 0">
					<b-button v-if="filters.length > 0" variant="danger" @click="clickBtnClearFilter" block>
						<i class="fa fa-ban" aria-hidden="true" />
						{{ FormMSG(156, 'Clear filter') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-else-if="!isMyPlannings">
			<b-row>
				<b-col :sm="`${filters.length > 0 ? 3 : 4}`">
					<dropdown-user-team
						:is-user="true"
						:users="lists.users"
						:initialize="initialize"
						:value-selected="userSelected"
						@user:selected="handleUserSelected"
						@user:removed="handleUserRemoved"
						style="width: 100%"
						:class="`${$screen.width < 768 ? 'mb-2' : ''}`"
					/>
				</b-col>
				<b-col :sm="`${filters.length > 0 ? 3 : 4}`">
					<dropdown-user-team
						:is-user="false"
						:teams="lists.teams"
						:initialize="initialize"
						:value-selected="teamSelected"
						@team:selected="handleTeamSelected"
						@team:removed="handleTeamRemoved"
						style="width: 100%"
						:class="`${$screen.width < 768 ? 'mb-2' : ''}`"
					/>
				</b-col>
				<b-col :sm="`${filters.length > 0 ? 3 : 4}`">
					<b-button
						:variant="haveFilter || isOpen ? 'warning' : 'light'"
						@click="isOpen = !isOpen"
						block
						:class="`${$screen.width < 768 && filters.length > 0 ? 'mb-2' : ''}`"
					>
						<i class="fa fa-filter" aria-hidden="true" />
						{{ FormMSG(95, 'Advanced filter') }}
					</b-button>
				</b-col>
				<b-col sm="3" v-if="filters.length > 0">
					<b-button variant="danger" @click="clickBtnClearFilter" block>
						<i class="fa fa-ban" aria-hidden="true" />
						{{ FormMSG(156, 'Clear filter') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div
			v-show="isOpen"
			id="container-filter"
			style="position: absolute; top: 35px; z-index: 4000; transition: width 0.4s; right: 0"
			:style="haveFilter ? 'width: 500px' : 'width: 200px'"
		>
			<b-card :title="FormMSG(58, 'Actif filters')">
				<b-card-text v-for="(filter, index) in filters" :key="index">
					<b-row v-if="!filter.singleFilterUser && !filter.singleFilterTeam && !filter.singleFilterClient">
						<b-col cols="2" class="pr-0">
							<v-select v-model="filter.firstOption" :options="firstOptions" :clearable="false" @option:selected="emitEvent" />
						</b-col>
						<b-col cols="3" class="pr-0">
							<v-select v-model="filter.filterBy" :options="listFilters" :clearable="false" @option:selected="emitEvent" />
						</b-col>
						<b-col :cols="filter.secondOption.value === 'undefined' || filter.secondOption.value === 'defined' ? 5 : 2" class="pr-0">
							<v-select v-model="filter.secondOption" :options="secondOptions" :clearable="false" @option:selected="emitEvent" />
						</b-col>
						<b-col v-if="filter.secondOption.value !== 'undefined' && filter.secondOption.value !== 'defined'" cols="3" class="pr-0">
							<list-filter-by
								:filter-by="filter.filterBy.value"
								:index-parent="index"
								:lists="lists"
								:value-selected="filter.filterSelected"
								@filter:selected="handleFilterSelected"
							/>
						</b-col>
						<b-col cols="2" class="pt-1 pr-0">
							<div class="btn btn-outline-danger" @click.prevent="deleteAFilter(index)">
								<i class="fa fa-times" aria-hidden="true" />
							</div>
						</b-col>
					</b-row>
				</b-card-text>
				<div>
					<b-dropdown class="card-link p-0" variant="link" toggle-class="text-decoration-none" no-caret>
						<template #button-content>
							<i class="fa fa-plus" aria-hidden="true"></i>
							{{ FormMSG(2, 'Add a filter') }}
						</template>
						<b-dropdown-item v-for="(listFilter, index) in listFilters" :key="index" @click.prevent="addNewFilter(listFilter)">
							{{ listFilter.label }}
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import languageMessages from '@/mixins/languageMessages';
import { getUsers } from '@/cruds/users.crud';
import { getTeams } from '@/cruds/team.crud';
import { getClients } from '@/cruds/clients.crud';
import { getTaskTypes } from '@/cruds/tasktypes.crud';
import ListFilterBy from './ListFilterBy';
import DropdownUserTeam from './DropdownUserTeam';
import OffersMixin from '@/components/Offers/offers.mixin';
import { getSuppliers } from '@/cruds/suppliers.crud';

export default {
	name: 'ActifFilter',

	components: {
		ListFilterBy,
		DropdownUserTeam
	},

	mixins: [languageMessages, OffersMixin],

	directives: {
		ClickOutside
	},

	props: {
		isNotPlanning: { type: Boolean, default: false }
	},

	data() {
		return {
			isOpen: false,
			filters: [],
			openListFilter: false,
			lists: {
				users: [],
				teams: [],
				clients: [],
				statuses: [],
				taskTypes: [],
				locations: [],
				priorities: [],
				architects: [],
				syndics: []
			},
			cookieName: '',
			cookiesActifFilter: {
				filters: [],
				initialize: false,
				teamSelected: null,
				userSelected: null,
				clientSelected: null
			},
			initialize: false,
			teamSelected: null,
			userSelected: null,
			clientSelected: null
		};
	},

	computed: {
		isMyPlannings() {
			if (this.$route.name === 'myPlannings' || this.$route.name === 'myTasks') {
				return true;
			} else {
				return false;
			}
		},
		haveFilter() {
			const findSingleFilterUserTeam = this.filters.findIndex((f) => !f.singleFilterTeam && !f.singleFilterUser && !f.singleFilterClient);
			if (findSingleFilterUserTeam !== -1) {
				if (this.filters.length > 0) {
					return true;
				}
			} else {
				return false;
			}
		},
		firstOptions() {
			return [
				{
					label: this.FormMSG(3, 'AND'),
					value: 'and'
				},
				{
					label: this.FormMSG(4, 'OR'),
					value: 'or'
				}
			];
		},
		listFilters() {
			if (!this.isMyPlannings) {
				let lists = [
					{
						label: this.FormMSG(5, 'User'),
						value: 'user'
					},
					{
						label: this.FormMSG(6, 'Team'),
						value: 'team'
					},
					{
						label: this.FormMSG(7, 'Client'),
						value: 'client'
					},
					{
						label: this.FormMSG(8, 'Status'),
						value: 'status'
					},
					{
						label: this.FormMSG(9, 'Task type'),
						value: 'tasktype'
					},
					{
						label: this.FormMSG(25, 'Priority'),
						value: 'priority'
					},
					{
						label: this.FormMSG(36, 'Syndic'),
						value: 'syndic'
					},
					{
						label: this.FormMSG(37, 'Architect'),
						value: 'architect'
					}
				];

				return lists;
			} else {
				return [
					{
						label: this.FormMSG(7, 'Client'),
						value: 'client'
					},
					{
						label: this.FormMSG(8, 'Status'),
						value: 'status'
					},
					{
						label: this.FormMSG(9, 'Task type'),
						value: 'tasktype'
					}
				];
			}
		},
		secondOptions() {
			return [
				{
					label: this.FormMSG(10, 'IS'),
					value: 'is'
				},
				{
					label: this.FormMSG(11, 'IS NOT'),
					value: 'isNot'
				},
				{
					label: this.FormMSG(12, 'IS DEFINED'),
					value: 'defined'
				},
				{
					label: this.FormMSG(13, 'IS UNDEFINED'),
					value: 'undefined'
				}
			];
		}
	},

	mounted() {
		this.getUsers();
		this.getTeams();
		this.getClients();
		this.getStatuses();
		this.getTaskTypes();
		this.getPriorities();
		this.getArchitects();
		this.getSyndics();
		this.getCookies();

		// this.getLocations()
	},

	methods: {
		getCookies() {
			switch (this.$route.name) {
				case 'taskList':
					this.cookieName = 'ck_actif_filter_for_taskList';
					break;
				case 'plannings':
					this.cookieName = 'ck_actif_filter_for_plannings';
					break;
				case 'assignationMultiple':
					this.cookieName = 'ck_actif_filter_for_plannings';
					break;
				case 'myPlannings':
					this.cookieName = 'ck_actif_filter_for_myPlannings';
					break;
				case 'myTasks':
					this.cookieName = 'ck_actif_filter_for_myTasks';
					break;
				case 'offers':
					this.cookieName = 'ck_actif_filter_for_offers';
					break;
				case 'AdministrationPlannings':
					this.cookieName = 'ck_actif_filter_for_admin_myPlannings';
					break;
				case 'administrationTasks':
					this.cookieName = 'ck_actif_filter_for_administration_tasks';
					break;
				case 'administrationOffers':
					this.cookieName = 'ck_actif_filter_for_administration_offers';
					break;
				case 'perAssignee':
					this.cookieName = 'ck_actif_filter_for_plannings';
					break;
			}
			if (this.$cookies.isKey(this.cookieName)) {
				this.cookiesActifFilter = this.$cookies.get(this.cookieName);
				this.filters = this.cookiesActifFilter.filters;
				this.initialize = this.cookiesActifFilter.initialize;
				this.teamSelected = this.cookiesActifFilter.teamSelected;
				this.userSelected = this.cookiesActifFilter.userSelected;
				this.clientSelected = this.cookiesActifFilter.clientSelected;
				//this.$cookies.remove('ck_actif_filter_for_plannings')
			} else {
				this.$cookies.set(this.cookieName, this.cookiesActifFilter, '2d');
			}
		},
		setCookies() {
			this.cookiesActifFilter.filters = this.filters;
			this.cookiesActifFilter.initialize = this.initialize;
			this.cookiesActifFilter.teamSelected = this.teamSelected;
			this.cookiesActifFilter.userSelected = this.userSelected;
			this.cookiesActifFilter.clientSelected = this.clientSelected;
			this.$cookies.set(this.cookieName, this.cookiesActifFilter, '2d');
		},
		handleClientRemoved() {
			this.filters = this.filters.filter((f) => !f.singleFilterClient);
			this.clientSelected = null;
			this.emitEvent();
		},
		handleClientSelected(payload) {
			this.filters.push({
				firstOption: { label: this.FormMSG(3, 'AND'), value: 'and' },
				filterBy: { label: 'Client', value: 'client' },
				secondOption: { label: this.FormMSG(10, 'IS'), value: 'is' },
				filterSelected: `${payload.id}`,
				singleFilterUser: false,
				singleFilterTeam: false,
				singleFilterClient: true
			});
			this.initialize = false;
			this.clientSelected = payload;
			this.emitEvent();
		},
		async clickBtnClearFilter() {
			this.initialize = true;
			this.filters = [];
			await this.handleUserRemoved();
			await this.handleTeamRemoved();
			await this.handleClientRemoved();
			await this.emitEvent();
		},
		handleUserSelected(payload) {
			this.filters.push({
				firstOption: { label: this.FormMSG(3, 'AND'), value: 'and' },
				filterBy: { label: 'User', value: 'user' },
				secondOption: { label: this.FormMSG(10, 'IS'), value: 'is' },
				filterSelected: `${payload.id}`,
				singleFilterUser: true,
				singleFilterTeam: false,
				singleFilterClient: false
			});
			this.initialize = false;
			this.userSelected = payload;
			this.emitEvent();
		},
		handleTeamSelected(payload) {
			this.filters.push({
				firstOption: { label: this.FormMSG(3, 'AND'), value: 'and' },
				filterBy: { label: 'Team', value: 'team' },
				secondOption: { label: this.FormMSG(10, 'IS'), value: 'is' },
				filterSelected: `${payload.id}`,
				singleFilterUser: false,
				singleFilterTeam: true,
				singleFilterClient: false
			});
			this.initialize = false;
			this.teamSelected = payload;
			this.emitEvent();
		},
		handleUserRemoved() {
			this.filters = this.filters.filter((f) => !f.singleFilterUser);
			this.userSelected = null;
			this.emitEvent();
		},
		handleTeamRemoved() {
			this.filters = this.filters.filter((f) => !f.singleFilterTeam);
			this.teamSelected = null;
			this.emitEvent();
		},
		handleFilterSelected({ index, payload }) {
			this.filters.map((filter, i) => {
				if (i === index) {
					filter.filterSelected = payload;
					filter.singleFilterUser = false;
					filter.singleFilterTeam = false;
					filter.singleFilterClient = false;
				}
			});
			this.initialize = false;
			this.emitEvent();
		},
		emitEvent() {
			this.setCookies();
			this.$emit('filters:selected', this.filters);
		},
		setIsOpen() {
			this.isOpen = false;
		},
		addNewFilter(filterBy) {
			this.filters.push({
				firstOption: { label: this.FormMSG(4, 'OR'), value: 'or' },
				filterBy: filterBy,
				secondOption: { label: this.FormMSG(10, 'IS'), value: 'is' },
				filterSelected: '',
				singleFilterTeam: false,
				singleFilterUser: false,
				singleFilterClient: false
			});
			this.changeWidthContainerFilter();
			this.emitEvent();
		},
		changeWidthContainerFilter() {
			this.$nextTick(() => {
				const containerFilter = document.getElementById('container-filter');
				containerFilter.style.width = '500px';
			});
		},
		deleteAFilter(indexFilter) {
			this.filters = this.filters.filter((filter, index) => index !== indexFilter);
			this.emitEvent();
		},
		async getArchitects() {
			this.lists.architects = await getSuppliers({
				name: null,
				type: 10
			});
		},
		async getSyndics() {
			this.lists.syndics = await getSuppliers({
				name: null,
				type: 11
			});
		},
		async getUsers() {
			this.lists.users = await getUsers({ myProject: true });
		},
		async getTeams() {
			this.lists.teams = await getTeams();
		},
		async getClients() {
			this.lists.clients = (await getClients()).clients;
		},
		async getStatuses() {
			this.lists.statuses = await this.FormMenu(1122);
		},
		async getPriorities() {
			this.lists.priorities = await this.FormMenu(1123);
		},
		async getTaskTypes() {
			this.lists.taskTypes = await getTaskTypes();
		}
		/*async getLocations() {
      const { data } = await apollo.query({
        query: gql`
          query {
            GetProjectLocationList {
              id
              setName
              setAddressId
            }
          }
        `,
        fetchPolicy: 'no-cache'
      })
      this.lists.locations = data.GetProjectLocationList
    },*/
	}
};
</script>
