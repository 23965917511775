<template>
	<div v-click-outside="hideSpecificDate" class="pb-3">
		<b-row>
			<b-col sm="4" v-if="!isOffer && isNotPlanning">
				<div style="font-size: 0.9rem" class="mt-1 font-weight-bold lh-20">
					<b-form-checkbox
						id="chckOnlyParent"
						v-model="chckOnlyParent"
						name="chckOnlyParent"
						value="true"
						unchecked-value="false"
						@change="handleChangeChckOnlyParent"
					>
						{{ FormMSG(75, 'Only task parent') }}
					</b-form-checkbox>
				</div>
			</b-col>
			<b-col :sm="`${!isOffer && isNotPlanning ? 4 : 6}`">
				<b-button
					variant="success"
					@click="
						$emit('today-event');
						activeView = 'day';
					"
					block
					:class="`${$screen.width < 768 ? 'mb-2' : ''}`"
				>
					{{ FormMSG(45, 'Today') }}
				</b-button>
			</b-col>
			<b-col :sm="`${!isOffer && isNotPlanning ? 4 : 6}`">
				<b-dropdown id="dropdown-1" variant="primary" :text="activeViewText" :disabled="showSpecificDate" style="width: 100%" menu-class="w-100">
					<b-dropdown-item @click="activeSpecificDate">
						{{ FormMSG(104, 'Specific date') }}
					</b-dropdown-item>
					<b-dropdown-item @click="setActiveView('year')">
						{{ FormMSG(98, 'Year') }}
					</b-dropdown-item>
					<b-dropdown-item @click="setActiveView('month')">
						{{ FormMSG(99, 'Month') }}
					</b-dropdown-item>
					<b-dropdown-item v-if="$screen.width > 990" @click="setActiveView('week')">
						{{ FormMSG(100, 'Week') }}
					</b-dropdown-item>
					<b-dropdown-divider />
					<b-dropdown-item @click="setActiveView('day')">
						{{ FormMSG(101, 'Day') }}
					</b-dropdown-item>
				</b-dropdown>
				<div v-if="showSpecificDate" style="width: 200px; top: 25px; z-index: 4000; right: 0; position: absolute">
					<b-card :title="FormMSG(106, 'Choose date')">
						<b-row>
							<b-col>
								<b-datepicker :locale="lang" :placeholder="FormMSG(106, 'Choose date')" dropright @input="handleInputSpecificDate" />
							</b-col>
						</b-row>
					</b-card>
				</div>
			</b-col>
		</b-row>
		<actif-filter :is-not-planning="isNotPlanning" @filters:selected="handleFiltersSelected" />
	</div>
</template>

<script>
import ActifFilter from '@/components/Plannings/Filter/ActifFilter';
import languageMessages from '@/mixins/languageMessages';
import ClickOutside from 'vue-click-outside';
import GlobalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import { getUserTeam } from '@/cruds/team.crud';
import OffersMixin from '@/components/Offers/offers.mixin';

export default {
	name: 'FilterPlanning',

	directives: {
		ClickOutside
	},

	components: {
		ActifFilter
	},

	mixins: [languageMessages, GlobalMixin, OffersMixin],

	props: {
		fieldFilter: { type: String, required: true },
		toMe: { type: Boolean, required: true },
		filterForTask: { type: Boolean, default: false },
		isNotPlanning: { type: Boolean, default: false },
		initActiveView: { type: String, default: 'week' },
		onlyParent: { type: Boolean, default: false }
	},

	data() {
		return {
			filters: [],
			activeView: 'day',
			showSpecificDate: false,
			specificDate: new Date().format('YYYY-MM-DD'),
			chckOnlyParent: false,
			cookieName: '',
			cookiesToSend: {
				filters: [],
				activeView: 'week',
				specificDate: new Date().format('YYYY-MM-DD'),
				chckOnlyParent: false
			}
		};
	},

	computed: {
		activeViewText() {
			if (this.activeView === 'year') {
				this.$emit('new-active-view', 'year');
				return this.FormMSG(91, 'Year');
			}
			if (this.activeView === 'month') {
				this.$emit('new-active-view', 'month');
				return this.FormMSG(92, 'Month');
			}
			if (this.activeView === 'week') {
				this.$emit('new-active-view', 'week');
				return this.FormMSG(93, 'Week');
			}
			if (this.activeView === 'day') {
				this.$emit('new-active-view', 'day');
				return this.FormMSG(94, 'Day');
			}
		}
	},

	watch: {
		onlyParent(val) {
			this.chckOnlyParent = val;
		},
		async toMe(val) {
			if (val) {
				this.filters.push({
					firstOption: { label: 'OR', value: 'or' },
					filterBy: { label: 'User', value: 'user' },
					secondOption: { label: 'IS', value: 'is' },
					filterSelected: store.state.myProfile.id,
					isToMe: true
				});

				const userTeams = await getUserTeam(store.state.myProfile.id);

				for (let i = 0; i < userTeams.length; i++) {
					const userTeam = userTeams[i];
					this.filters.push({
						firstOption: { label: 'OR', value: 'or' },
						filterBy: { label: 'Team', value: 'team' },
						secondOption: { label: 'IS', value: 'is' },
						filterSelected: userTeam.id,
						isToMe: true
					});
				}

				await this.handleFiltersSelected(this.filters, true);
			} else {
				this.filters = this.filters.filter((f) => !f.isToMe);
				await this.handleFiltersSelected([], true);
			}
		}
	},

	/* async created() {
    await this.getCookies()
  }, */

	mounted() {
		this.activeView = this.initActiveView;
		this.getCookies();
	},

	methods: {
		getCookies() {
			switch (this.$route.name) {
				case 'taskList':
					this.cookieName = 'ck_filter_planning_for_taskList';
					break;
				case 'plannings':
					this.cookieName = 'ck_filter_planning_for_plannings';
					break;
				case 'myPlannings':
					this.cookieName = 'ck_filter_planning_for_myPlannings';
					break;
				case 'myTasks':
					this.cookieName = 'ck_filter_planning_for_myTasks';
					break;
				case 'offers':
					this.cookieName = 'ck_filter_planning_for_offers';
					break;
				case 'AdministrationPlannings':
					this.cookieName = 'ck_filter_planning_for_admin_myPlannings';
					break;
				case 'administrationTasks':
					this.cookieName = 'ck_filter_planning_for_administration_tasks';
					break;
				case 'administrationOffers':
					this.cookieName = 'ck_filter_planning_for_administration_offers';
					break;
				case 'perAssignee':
					this.cookieName = 'ck_filter_planning_for_plannings';
					break;
				case 'assignationMultiple':
					this.cookieName = 'ck_filter_planning_for_plannings';
					break;
			}

			if (this.$cookies.isKey(this.cookieName)) {
				this.cookiesToSend = this.$cookies.get(this.cookieName);

				this.filters = this.cookiesToSend.filters;
				this.activeView = this.cookiesToSend.activeView;
				this.specificDate = this.cookiesToSend.specificDate;
				this.chckOnlyParent = this.cookiesToSend.chckOnlyParent;
				//this.$cookies.remove('ck_filter_plannings_for_plannings')
			} else {
				this.$cookies.set(this.cookieName, this.cookiesToSend, '2d');
			}
		},
		setCookies() {
			this.cookiesToSend.filters = this.filters;
			this.cookiesToSend.activeView = this.activeView;
			this.cookiesToSend.specificDate = this.specificDate;
			this.cookiesToSend.chckOnlyParent = this.chckOnlyParent;

			this.$cookies.set(this.cookieName, this.cookiesToSend, '2d');
		},
		handleChangeChckOnlyParent(payload) {
			this.chckOnlyParent = payload;
			this.setCookies();
			this.$emit('only-parent:change', payload);
		},
		handleInputSpecificDate(payload) {
			this.specificDate = payload;

			this.setCookies();
			this.$emit('specific-date:selected', {
				startDate: `${payload}T00:00:00Z`,
				endDate: `${payload}T23:59:59Z`
			});
			this.hideSpecificDate();
		},
		activeSpecificDate() {
			this.showSpecificDate = true;
			this.setActiveView('day');
		},
		hideSpecificDate() {
			this.showSpecificDate = false;
		},
		setActiveView(label) {
			this.activeView = label;
			this.setCookies();
		},
		handleFiltersSelected(payload, init = false) {
			if (this.toMe && !init) {
				this.filters = [
					{
						firstOption: { label: 'AND', value: 'and' },
						filterBy: { label: 'User', value: 'user' },
						secondOption: { label: 'IS', value: 'is' },
						filterSelected: store.state.myProfile.id,
						isToMe: true
					},
					...payload
				];
			} else if (!this.toMe) {
				this.filters = payload;
			}

			this.cookiesToSend.filters = this.filters;
			this.$cookies.set(this.cookieName, this.cookiesToSend, '2d');
			const condition = this.getConditionFilter();
			this.setCookies();
			this.$emit('filters:selected', {
				payload,
				condition
			});
		},
		getConditionFilter() {
			let filterCondition = '(';
			// Loop for the list filters
			const listFilters = ['user', 'team', 'client', 'status', 'tasktype', 'priority', 'architect', 'syndic'];
			// Loop for the first option
			const arrFirstOptions = ['or', 'and'];
			// Loop for the second option
			const arrSecondOptions = ['is', 'isNot', 'defined', 'undefined'];
			for (let i = 0; i < this.filters.length; i++) {
				const filter = this.filters[i];
				for (let j = 0; j < listFilters.length; j++) {
					if (filter.filterBy.value === listFilters[j]) {
						for (let k = 0; k < arrSecondOptions.length; k++) {
							for (let l = 0; l < arrFirstOptions.length; l++) {
								if (filter.firstOption.value === arrFirstOptions[l] && filter.secondOption.value === arrSecondOptions[k]) {
									if (!this.filterForTask) {
										filterCondition += this.prepareFilterForPlanning(
											i,
											filter.firstOption.value,
											filter.filterBy.value,
											filter.secondOption.value,
											filter.filterSelected
										);
									} else {
										filterCondition += this.prepareFilterForTask(
											i,
											filter.firstOption.value,
											filter.filterBy.value,
											filter.secondOption.value,
											filter.filterSelected
										);
									}
								}
							}
						}
					}
				}
			}

			return filterCondition + ')';
		},
		prepareFilterForPlanning(index, firstOption, filterBy, secondOption, filterSelected) {
			const signeFirstOption = { or: ' || ', and: ' && ' };
			const signeSecondOption = {
				is: ' === ',
				isNot: ' !== ',
				defined: ' !== 0',
				undefined: ' === 0 '
			};
			const columns = {
				status: `${this.fieldFilter}.status`,
				client: `${this.fieldFilter}.clientId`,
				tasktype: `parseInt(${this.fieldFilter}.taskType.id)`,
				user: `${this.fieldFilter}.taskResourceUsers.findIndex(u => parseInt(u.id)`,
				team: `${this.fieldFilter}.taskResourceTeams.findIndex(t => parseInt(t.id)`,
				priority: `${this.fieldFilter}.priority`,
				architect: `${this.fieldFilter}.architectId`,
				syndic: `${this.fieldFilter}.syndicId`
			};
			let result = '';
			if (filterSelected !== '') {
				if (index > 0) {
					result += `${signeFirstOption[firstOption]} `;
				}
				if (filterBy === 'team' || filterBy === 'user') {
					result += `${columns[filterBy]} ${signeSecondOption[secondOption]} ${filterSelected}) !== -1`;
				} else if (filterBy !== 'team' && filterBy !== 'user') {
					result += `${columns[filterBy]} ${signeSecondOption[secondOption]} ${filterSelected}`;
				}
			} else {
				if (index > 0 && filterSelected.length > 0) {
					result += `${signeFirstOption[firstOption]} `;
				}
				if (secondOption === 'defined' && filterBy === 'team') {
					result += `${this.fieldFilter}.taskResourceTeams.length > 0`;
				} else if (secondOption === 'defined' && filterBy === 'user') {
					result += `${this.fieldFilter}.taskResourceUsers.length > 0`;
				} else if (secondOption === 'undefined' && filterBy === 'team') {
					result += `${this.fieldFilter}.taskResourceTeams.length === 0`;
				} else if (secondOption === 'undefined' && filterBy === 'user') {
					result += `${this.fieldFilter}.taskResourceUsers.length === 0`;
				} else if ((secondOption === 'defined' || secondOption === 'undefined') && filterBy !== 'user' && filterBy !== 'team') {
					result += `${columns[filterBy]} ${signeSecondOption[secondOption]} ${filterSelected}`;
				}
			}

			return result;
		},
		prepareFilterForTask(index, firstOption, filterBy, secondOption, filterSelected) {
			const signeFirstOption = { or: ' || ', and: ' && ' };
			const signeSecondOption = {
				is: ' === ',
				isNot: ' !== ',
				defined: ' !== 0',
				undefined: ' === 0 '
			};
			const columns = {
				status: `${this.fieldFilter}.status`,
				client: `${this.fieldFilter}.clientId`,
				tasktype: `parseInt(${this.fieldFilter}.taskType.id)`,
				user: `${this.fieldFilter}.assignedTo.findIndex(u => parseInt(u.id)`,
				team: `${this.fieldFilter}.assignedTeam.findIndex(t => parseInt(t.id)`,
				priority: `${this.fieldFilter}.priority`,
				architect: `${this.fieldFilter}.architectId`,
				syndic: `${this.fieldFilter}.syndicId`
			};
			let result = '';
			if (filterSelected !== '') {
				if (index > 0) {
					result += `${signeFirstOption[firstOption]} `;
				}
				if (filterBy === 'team' || filterBy === 'user') {
					result += `${columns[filterBy]} ${signeSecondOption[secondOption]} ${filterSelected}) !== -1`;
				} else if (filterBy !== 'team' && filterBy !== 'user') {
					result += `${columns[filterBy]} ${signeSecondOption[secondOption]} ${filterSelected}`;
				}
			} else {
				if (index > 0 && filterSelected.length > 0) {
					result += `${signeFirstOption[firstOption]} `;
				}
				if (secondOption === 'defined' && filterBy === 'team') {
					result += `${this.fieldFilter}.assignedTeam.length > 0`;
				} else if (secondOption === 'defined' && filterBy === 'user') {
					result += `${this.fieldFilter}.assignedTo.length > 0`;
				} else if (secondOption === 'undefined' && filterBy === 'team') {
					result += `${this.fieldFilter}.assignedTeam.length === 0`;
				} else if (secondOption === 'undefined' && filterBy === 'user') {
					result += `${this.fieldFilter}.assignedTo.length === 0`;
				} else if ((secondOption === 'defined' || secondOption === 'undefined') && filterBy !== 'user' && filterBy !== 'team') {
					result += `${columns[filterBy]} ${signeSecondOption[secondOption]} ${filterSelected}`;
				}
			}

			return result;
		}
	}
};
</script>
